import axios from "axios";

// static data for HomePage white cards
const primaryCardData = [
    {
        id: 0,
        title: "Ρωτήστε μας",
        content: "Η αρθρογραφία μας παρέχει ολοκληρωμένη υποστήριξη των επιχειρήσεων σε όλο τον κύκλο ζωής τους, κατά την ίδρυση, την λειτουργία και την περίοδο της διακοπής της λειτουργίας τους. Επίσης παρέχουμε σημαντικές πληροφορίες που βοηθούν στην διοίκηση, την ανάπτυξη και την χρηματοδότηση μίας επιχείρησης. Μέσα από την αρθογραφία μας προσπαθούμε να σας παρέχουμε πληροφορίες ώστε να μπορέσετε να αυξήσετε την εξωστρέφεια και τρόπο που μπορεί να κάνετε εξαγωγές.Για κάθε μία από τις παραπάνω κατηγορίες υπάρχει αρθρογραφία με πλήρες περιεχόμενο που καλύπτει την τρέχουσα νομοθεσία και τους κανονισμούς καθώς και την επικαιρότητα. Αν κάτι δεν σας καλύπτει, εγγραφείτε για να υποβάλετε ηλεκτρονικά το ερώτημά σας σχετικά με την υπηρεσία που σας ενδιαφέρει."
    },
    {
        id: 1,
        title: "Θα σας απαντήσουμε",
        content: "Αυτόματα, το ερώτημά σας θα καταχωρηθεί και θα εξετασθεί από εξειδικευμένο σύμβουλο ή / και συνεργάτη του φορέα. Οι συνεργάτες του φορέα αφού εξετάσουν το ερώτημά σας, θα υποβάλλουν μέσω του συστήματος την απάντηση και εσείς θα ενημερωθείτε σχετικά μεσω emai. Επίσης μπορείτε μέσα από εδώ, να δείτε όλα τα ερωτήματα που αφορούν την υπηρεσία που σας ενδιαφέρει και έχουν υποβληθεί είτε από εσάς είτε από άλλη επιχείρηση καθώς και την απάντηση αυτών."
    }
];
// clear spaces, seperate with commas
const parseKeywords = (keywordsString) => {
    let k = keywordsString.trim().replaceAll(/,/g, ' ');
    k = k.replaceAll(/\s\s+/g, ',');

    return k;
};
// download a saved file
export async function downloadFile(fileName, fileId) {
    let params = {};
    if (fileId && fileId != '') params.fileId = fileId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };
    const responseType = 'blob';
    try {
        const question = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/download/' + fileName),
            { params, headers, responseType });
        if (question.data) {
            const type = question.headers['content-type'];
            const blob = new Blob([question.data], { type: type });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// delete an attached file
export async function deleteFile(fileId) {
    let params = {};
    if (fileId && fileId != '') params.fileId = fileId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };
    try {
        await axios.delete(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/deleteFile/'),
            { params, headers });
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// big white cards in HomePage. Can change in number (normally 2)
export function getPrimaryCardData() {
    return primaryCardData;
}
// latest published & answered questions. Always 6
export async function getRecentQuestions(lang) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;

    try {
        const recentQuestions = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/recentQuestions'),
            { params });
        return recentQuestions.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// Check if keycloak user is register in chamber Database, if not auto registration
export async function postCheckUser(keycloakId, email, lastName, firstName, gemiNumber) {
    let userInfos;
    try {
        userInfos = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/checkUser'), {},
            { params: { keycloakId, email, lastName, firstName, gemiNumber } }
        );
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
    return userInfos.data;
}
// all users
export async function getUserData() {
    try {
        const users = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/users'));
        return users.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// all sections, depends on category
export async function getSectionData(lang, category) {
    if (lang == 'gr') lang = 'el';
    if (category != '') {
        try {
            const sections = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/sections'), { params: { lang, categoryId: category } });
            return sections.data;
        } catch (error) {//i dont print the error because it exposes the request
            return null;
        }
    }
}
// all categories
export async function getCategoryData(lang) {
    if (lang == 'gr') lang = 'el';
    try {
        const categories = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/categories'), { params: { lang } });
        return categories.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// only for /management
export async function getManagementSearchResults(lang, tab, page, pageSize, category, section, keywords, creationstartdate, creationenddate, answerstartdate, answerenddate, completed, user) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (tab == 'search') tab = 'general';
    if (tab == 'complete') tab = 'completed';
    params.managementTab = tab;
    params.offset = (page - 1) * pageSize;
    params.limit = pageSize;
    if (category && category != '') params.categoryId = category;
    if (section && section != '') params.sectionId = section;
    if (keywords && keywords != '') params.keywords = parseKeywords(keywords);
    if (creationstartdate && creationstartdate != '') params.dateFrom = creationstartdate.toISOString();
    if (creationenddate && creationenddate != '') params.dateTo = creationenddate.toISOString();
    if (answerstartdate && answerstartdate != '') params.answerDateFrom = answerstartdate.toISOString();
    if (answerenddate && answerenddate != '') params.answerDateTo = answerenddate.toISOString();
    if (completed && completed != '') params.completed = completed;
    if (user && user != '') params.userListId = user;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        Authorization: 'bearer ' + token,
    };

    try {
        const results = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/managementQuestions'),
            { params, headers });
        return results.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// get a question in specific language
export async function getQuestion(id, lang) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    if (lang) params.lang = lang;
    if (id && id != '') params.questionId = id;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };
    try {
        const question = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/question'),
            { params, headers });
        return question.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// create a new question or add translation to an existing one
export async function postQuestion(id, lang, category, section, title, question, fileDescription, published, answer, file, showName, allowPublish) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    const userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (id && id != '') params.id = id;
    if (category && category != '') params.categoryId = category;
    if (section && section != '') params.sectionId = section;
    if (title && title != '') params.title = title;
    if (question && question != '') params.question = question;
    if (fileDescription && fileDescription != '') params.fileName = fileDescription;
    params.publishFlag = !!published;
    params.showNameFlag = !!showName;
    params.allowPublishFlag = !!allowPublish;
    if (answer && answer != '') params.answer = answer;

    let formData = new FormData();
    if (file) {
        formData.append("file", file, file.name);
    }

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'bearer ' + token,
    };
    try {
        const results = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/addQuestion'),
            formData,
            { params, headers });
        return results.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// update question in specific language
export async function updateQuestion(lang, question, file) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    const userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (question.id && question.id != '') params.questionId = question.id;
    if (question.category.id && question.category.id != '') params.categoryId = question.category.id;
    if (question.section.id && question.section.id != '') params.sectionId = question.section.id;
    if (question.title && question.title != '') params.title = question.title;
    if (question.question && question.question != '') params.question = question.question;
    if (question.fileDescription && question.fileDescription != '') params.fileName = question.fileDescription;
    params.publishFlag = question.publishFlag;
    params.showNameFlag = question.showNameFlag;
    params.allowPublishFlag = question.allowPublishFlag;
    if (question.answer && question.answer != '') params.answer = question.answer;

    let formData = new FormData();
    if (file) {
        formData.append("file", file, file.name);
    }

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'bearer ' + token,
    };
    try {
        const results = await axios.put(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/updateQuestion/' + question.id),
            formData,
            { params, headers });
        return results.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// answers a question in any number of langs. Currently unused
export async function answerQuestion(lang, questionId, answers) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    const userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (questionId) params.questionId = questionId;

    let body = answers;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };
    try {
        const results = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/multilanguageAnswer'),
            body,
            { params, headers });
        return results.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// if lang is not given, entire question is deleted
export async function deleteQuestion(id, lang) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    if (lang) params.lang = lang;
    if (id && id != '') params.questionId = id;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };
    try {
        const response = await axios.delete(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/deleteQuestion'),
            { params, headers });
        return response.data.statusCode;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// adds question to user's routing list
export async function routeQuestion(id, user, notes, date) {
    let params = {};
    const userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (id) params.questionId = id;

    let body = {};
    if (user) body.toUserId = user;
    if (notes) body.notes = notes;
    if (date) body.dueDate = date.toISOString();

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };
    try {
        const res = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/routeQuestion'),
            body,
            { params, headers });
        return res.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// gets user's favorites
export async function getFavorites(lang) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    // no point fetching favorites if no userId exists
    if (userId) {
        params.userId = userId;

        let token = sessionStorage.getItem('authentication');
        let headers = {
            Authorization: 'bearer ' + token,
        };
        try {
            const favorites = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/favorites/favorites'),
                { params, headers });
            return favorites.data;
        } catch (error) {//i dont print the error because it exposes the request
            return null;
        }
    }
    return [];
}
// gets numbers for management tabs 'notification'
export async function getBadges() {
    let params = {};
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        Authorization: 'bearer ' + token,
    };
    try {
        const badges = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/management/badges'),
            { params, headers });
        return badges.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// add a favorite
export async function postFavorite(id) {
    let body = {};
    const userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) body.userId = userId;
    if (id && id != '') body.requestId = id;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };
    try {
        const response = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/favorites/addFavorite'),
            body,
            { headers });
        return response.status;
    } catch (error) {//i dont print the error because it exposes the request
        return 500;
    }
}
// delete a favorite
export async function deleteFavorite(id) {
    let data = {};
    if (id && id != '') data.requestId = id;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) data.userId = userId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };
    try {
        const response = await axios.delete(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/favorites/deleteFavorite'),
            { data, headers });
        return response.status;
    } catch (error) {//i dont print the error because it exposes the request
        return 500;
    }
}
// get a bunch of questions from their ids. Can also be filtered with the rest of the params. mainly for /favorites
export async function getQuestionsByIds(lang, ids, category, section, keywords, startdate, enddate) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    if (ids && ids.length > 0) params.ids = ids;
    if (category && category != '') params.categoryId = category;
    if (section && section != '') params.sectionId = section;
    if (keywords && keywords != '') params.keywords = parseKeywords(keywords);
    if (startdate && startdate != '') params.dateFrom = startdate.toISOString();
    if (enddate && enddate != '') params.dateTo = enddate.toISOString();

    let token = sessionStorage.getItem('authentication');
    let headers = {
        Authorization: 'bearer ' + token,
    };
    try {
        const questions = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/questionsByIds'),
            { params, headers });
        return questions.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// all published questions that match the filters. for /search
export async function getSearchResults(lang, page, pageSize, category, section, keywords, startdate, enddate) {
    let params = {};
    if (lang == 'gr') lang = 'el';
    params.lang = lang;
    params.offset = (page - 1) * pageSize;
    params.limit = pageSize;
    if (category && category != '') params.categoryId = category;
    if (section && section != '') params.sectionId = section;
    if (keywords && keywords != '') params.keywords = parseKeywords(keywords);
    if (startdate && startdate != '') params.dateFrom = startdate.toISOString();
    if (enddate && enddate != '') params.dateTo = enddate.toISOString();
    let userId = JSON.parse(sessionStorage.getItem('userSession'))?.id;
    if (userId) params.userId = userId;
    try {
        const results = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/questions'), { params });
        return results.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// get all categories/sections 
export async function getParametricFiles(type, catId) {
    let params = { type };
    if (catId && catId != '') params.catId = catId;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };

    try {
        const parametricFiles = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/parametricFiles/'), { params, headers });
        return parametricFiles.data.list;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// get specific parametric file 
export async function getParametricFile(type, id) {
    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };

    try {
        const parametricFiles = await axios.get(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/parametricFiles/' + id), { params: { type }, headers });
        return parametricFiles.data;
    } catch (error) {//i dont print the error because it exposes the request
        return null;
    }
}
// add category/section
export async function addParametricFile(type, desc, catId) {
    let params = { type };
    if (catId) params.catId = catId;
    let body = desc;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };

    try {
        const res = await axios.post(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/parametricFiles/new'), body, { params, headers });
        return res.status;
    } catch (error) {//i dont print the error because it exposes the request
        return 500;
    }
}
// update category/section
export async function updateParametricFile(type, id, desc) {
    let body = desc;

    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
    };

    try {
        const res = await axios.put(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/parametricFiles/' + id), body, { params: { type }, headers });
        return res.status;
    } catch (error) {//i dont print the error because it exposes the request
        return 500;
    }
}
// delete category/section
export async function deleteParametricFile(type, id) {
    let token = sessionStorage.getItem('authentication');
    let headers = {
        'Authorization': 'bearer ' + token,
    };

    try {
        const res = await axios.delete(process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat('/parametricFiles/' + id), { params: { type }, headers });
        return res.status;
    } catch (error) {//i dont print the error because it exposes the request
        return 500;
    }
}
